<template>
  <div>
    <div class="container">
      <h2>Server Details</h2>
      <table>
        <thead>
          <tr>
            <th>Connected Users</th>
            <th>Server Host</th>
            <th>Client Host</th>
            <th>WS URL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="appDet">
            <td>{{ appDet.connectionData.connectionCount }}</td>
            <td>{{ specificApp.server_host }}</td>
            <!-- <td>{{ appDet.serverOn == true ? "Running" : "Disconnected" }}</td> -->
            <td>{{ specificApp.client_host }}</td>
            <td>{{ appDet.url }}</td>
          </tr>
        </tbody>
      </table>
      <h2
        v-if="appDet"
        v-show="!!parseInt(appDet.connectionData.connectionCount)"
      >
        Connections
      </h2>
      <table
        v-if="appDet"
        v-show="!!parseInt(appDet.connectionData.connectionCount)"
      >
        <thead>
          <tr>
            <th>ID</th>
            <!-- <th>User Name</th> -->
            <th>User Profile</th>
            <th>User Rooms</th>
            <th>User IP</th>
            <th>Upload</th>
            <th>Connected At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(con, index) in appDet.logs" :key="index">
            <td>
              {{ con.id }}
            </td>
            <!-- <td>
              <strong>
                {{ con.user ? con.user.name : "" }}
              </strong>
            </td> -->
            <td>
              <div class="user-profile">
                <div v-for="(value, param) in con.user" :key="param">
                  <span class="user-profile-param">{{ param }}:</span>
                  <span
                    :class="{
                      profileUsername: param.trim().toLowerCase() === 'name',
                    }"
                  >
                    {{ value }}
                  </span>
                </div>
              </div>
            </td>
            <td class="rooms">
              <span v-for="(room, index) in con.rooms" :key="index">
                <span v-if="index < 3">
                  {{ room
                  }}<span v-if="con.rooms.length - 1 > index && index != 2"
                    >,
                  </span>
                </span>
                <span v-else-if="index == 3"> ... </span>
              </span>
              <div class="allRooms" v-if="con.rooms.length > 3">
                <h4>{{ con.user ? con.user.name : "" }} Rooms</h4>
                <span v-for="(room, index) in con.rooms" :key="index">
                  {{ index + 1 }}. {{ room }}
                </span>
              </div>
            </td>
            <td>
              {{ con.ip.replace("::ffff:", "") }}
            </td>
            <td>
              <span v-if="'upload' in con">
                <strong>{{ con.upload.perSecond }}</strong>
                <br />
                <small style="color: lightslategray">
                  <!-- <template v-if="con.upload.size"> -->
                  ({{ con.upload.size }} bytes)
                  <!-- </template> -->
                </small>
              </span>
            </td>
            <td>
              <div>
                {{ con.connected_at.format("DD/MM/YY HH:mm:ss") }}
              </div>
              <div>
                {{ con.connected_at.fromNow() }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <h3>Publish</h3>
      <form
        id="publish-form"
        ref="publishForm"
        @submit.prevent="submitPublishForm"
      >
        <div>
          <input v-model="publishForm.event" type="text" placeholder="Event" />
          <input v-model="publishForm.rooms" type="text" placeholder="Rooms" />
          <input v-model="publishForm.data" type="text" placeholder="Data" />
          <button>Publish</button>
        </div>
      </form>
    </div>
    <div v-if="appDet && appDet.dataLogs" class="events-table">
      <h3>Events</h3>
      <div class="events-table-container">
        <table>
          <thead>
            <th>Time</th>
            <th>Action</th>
            <th>Emitter</th>
            <th>Name</th>
            <th>Event</th>
            <th>Rooms</th>
          </thead>
          <tbody>
            <tr v-for="log in appDet.dataLogs" :key="log.time">
              <td>
                <strong>{{ log.time.format("DD/MM/YY HH:mm:ss") }}</strong>
                <small>:{{ log.time.format("SSS") }}</small>
              </td>
              <td>{{ log.action }}</td>
              <td>{{ log.emitter }}</td>
              <td>{{ log.name }}</td>
              <td>{{ log.event || "---" }}</td>
              <td>
                {{ Array.isArray(log.rooms) ? log.rooms.join(", ") : "---" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import axios from "@/helpers/axios";
export default {
  name: "AppDetails",
  data() {
    return {
      publishForm: {
        event: "device",
        rooms: "all-devices",
        data: "device data",
      },
    };
  },
  mounted() {
    console.log("Details Mounted!");
  },

  computed: {
    specificApp() {
      const app = this.$store.getters["apps/app"](this.$route.params.name);
      return app;
    },
    appDet() {
      const namespace = "/" + this.specificApp.name.toLowerCase();
      let cons = this.$store.getters["appDetails/app"](namespace);
      if (cons) {
        cons = JSON.parse(JSON.stringify(cons));
        cons.logs = cons.logs.map((l) => {
          l.connected_at = dayjs(l.connected_at);
          return l;
        });
        cons.dataLogs = cons.dataLogs
          .sort((a, b) => b.time - a.time)
          .map((l) => {
            l.time = dayjs(l.time);
            return l;
          });
      }
      return cons;
    },
  },
  watch: {},
  methods: {
    submitPublishForm() {
      const form = { ...this.publishForm };
      console.log("form.rooms", form.rooms);
      form.rooms = form.rooms.split(",").map((r) => r.trim());
      form.app = this.specificApp.name;
      console.log("submitPublishForm() !!!", form);
      axios
        .post("apps/publish", form)
        .then((res) => {
          console.log("res", res.data);
        })
        .catch((err) => {
          console.log("err", err.response);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin: 20px 40px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // border: solid 2px black;
  border-radius: 6px;
  h2 {
    padding-left: 20px;
    text-align: left;
  }
}

table {
  margin-bottom: 2px;
  border-radius: 10px;
  width: 100%;
  thead {
    text-align: center;
    border-radius: 10px;
  }
  td {
    padding: 8px 16px;
    .user-profile {
      display: flex;
      flex-direction: column;
      div {
        padding: 4px;
        text-align: start;
        .user-profile-param {
          display: inline-block;
          margin-inline-end: 4px;
          font-weight: 700;
          text-decoration: underline;
          min-width: 80px;
          &:first-letter {
            text-transform: uppercase;
          }
        }
        .profileUsername {
          font-weight: 700;
          font-size: 14pt;
        }
      }
    }
  }
}

table tbody tr:hover {
  // background-color: rgb(144, 184, 184);
  background-color: rgba(122, 0, 204, 0.1);
  // cursor: pointer;
}

.rooms {
  // background-color: red;
  position: relative;
  .allRooms {
    display: none;
    border-radius: 5px;
    top: -40px;
    left: -40px;
    min-width: 160px;
    max-height: 400px;
    overflow-y: auto;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.5);
    z-index: 50;
    h4 {
      padding: 0;
      display: inline-block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    span {
      text-align: left;
      padding: 8px 16px;
      border-top: solid 1px #888;
      &:nth-child(even) {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
  &:hover {
    .allRooms {
      display: flex;
    }
  }
}
.events-table {
  margin-bottom: 30px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .events-table-container {
    max-width: 1200px;
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      margin-top: 40px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid #888;
      border-radius: 5px;
    }
    table {
      thead {
        position: sticky;
        top: -1px;
        height: 40px;
        th {
          min-width: 100px;
        }
      }
      tbody {
        tr {
          td {
            padding: 8px 16px;
            small {
              color: #888;
            }
          }
        }
      }
    }
  }
}
</style>
