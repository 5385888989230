<template>
  <div v-if="app">
    <h1>{{ app.name }}</h1>
    <AppDetailsComp />
  </div>
</template>
<script>
import AppDetailsComp from "../components/AppDetailsComp.vue";
export default {
  components: { AppDetailsComp },
  name: "AppPage",
  mounted() {
    console.log("App Page Mounted");
  },
  computed: {
    app() {
      if (this.$route.params.name) {
        return this.$store.getters["apps/app"](this.$route.params.name);
      }
      return null;
    },
    apps() {
      return this.$store.getters["apps/apps"];
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-family: "Times New Roman", Times, serif;
}
</style>
